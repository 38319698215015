<template>
  <base-card>
    <v-card-title>
      {{ `${$t('common.upload')} ${$t('access.openinfra')}` }}
    </v-card-title>
    <v-divider />
    <v-card-text class="pb-0">
      <div class="mb-2">
        {{ $t("access.lastUpdatedDate") }}:
        <span class="font-weight-bold red--text">{{ lastUpdatedDate }}</span>
      </div>

      <file-uploader
        ref="fileUploader"
        accepted-file-types=".csv"
        :dict-default-message="$t('access.clickToUpload', { type: 'CSV' })"
        :url="uploadCsvURL"
        :upload-multiple="false"
        :timeout="1000"
        :is-disable="isLoading"
        @uploadComplete="uploadSuccess"
        @uploadStart="uploadStart"
      />
    </v-card-text>

    <v-card-actions class="justify-center pb-4">
      <v-btn
        class="primary px-4"
        :disabled="isLoading"
        @click="updateData"
      >
        {{ $t("access.updateAutomatically") }}
      </v-btn>
    </v-card-actions>
  </base-card>
</template>

<script>
  import { mapActions } from 'vuex'

  import util from '@/utils'
  import { showSuccessDialog, showErrorDialog } from '@/utils/swalDialog'

  import FileUploader from '../components/FileUploader'

  export default {
    components: {
      FileUploader,
    },

    data () {
      return {
        lastUpdatedDate: 'N/A',
        isLoading: false,
      }
    },

    computed: {
      uploadCsvURL () {
        return `${util.apiURL}openNetwork/uploadAccessFile?type=openinfra&format=csv`
      },
    },

    mounted () {
      this.getDataLastUpdatedDate()
    },

    methods: {
      ...mapActions({
        getLastUpdatedDate: 'getLastUpdatedDate',
        updateAccesses: 'updateAccesses',
      }),

      async getDataLastUpdatedDate () {
        try {
          const response = await this.getLastUpdatedDate({
            type: 'openinfra',
          })
          this.lastUpdatedDate = util.formatDateTime(response?.lastUpdateDate)
        } catch (error) {
          this.lastUpdatedDate = 'N/A'
        }
      },

      uploadStart() {
        this.isLoading = true
      },

      uploadSuccess() {
        this.lastUpdatedDate = util.formatDateTime(new Date())
        this.isLoading = false
      },

      async updateData() {
        this.isLoading = true
        try {
          await this.updateAccesses('openinfra')

          showSuccessDialog(
            this.$t('access.wait'),
            this.$t('access.willTakeMoreTimes')
          )

          this.lastUpdatedDate = util.formatDateTime(new Date())
        } catch (error) {
          const errorText = util.getErrorResponse(error)
          showErrorDialog(this.$t('common.error'), errorText)
        }
        this.isLoading = false
      },
    },
  }
</script>
