<template>
  <v-row class="upload-accesses">
    <v-col
      cols="12"
      md="6"
    >
      <upload-sollentuna />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <upload-openinfra />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <upload-ip-only />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <upload-zitius />
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <upload-openuniverse />
    </v-col>
  </v-row>
</template>

<script>
  import UploadSollentuna from './UploadSollentuna'
  import UploadOpeninfra from './UploadOpeninfra'
  import UploadOpenuniverse from './UploadOpenuniverse'
  import UploadIpOnly from './UploadIpOnly'
  import UploadZitius from './UploadZitius'

  export default {
    metaInfo: {
      title: 'Upload Accesses',
    },

    components: {
      UploadSollentuna,
      UploadOpeninfra,
      UploadOpenuniverse,
      UploadIpOnly,
      UploadZitius,
    },
  }
</script>
